<template>
  <div class="page">
    <navTop title="" :leftIcon="true"></navTop>
    <div class="home-title">武夷生活</div>
    <div class="live-tab">
      <div
        class="live-tab-item"
        @click="toLiveDetail(item)"
        v-for="(item, index) in lifeList"
        :key="index"
      >
        <img :src="item.icon_img" class="live-icon" />
        <div class="live-tab-name">{{ item.name }}</div>
      </div>
    </div>
    <van-loading size="24px" vertical v-if="loading">加载中...</van-loading>
  </div>
</template>
<script>
import { lifeList } from "@/api/index";
import navTop from "@/components/common/navTop.vue";
import axios from "axios";
export default {
  components: {
    navTop,
  },
  data() {
    return {
      lifeList: [],
      loading:false
    };
  },
  methods: {
    async getLifeList() {
      
      try {
        const { code, data } = await lifeList();
        this.lifeList = data.map((item) => {
          item.icon_img = process.env.VUE_APP_API + item.icon_image;
          return item;
        });
      } catch (e) {
        console.log(e);
      }
    },

    toLiveDetail(item) {
      this.loading = true;
      axios
        .get("https://wysly.wyssly.com/home/index/wangeMenu")
        .then( (res) => {
          this.loading = false;
          let found = {};
          res.data.data.forEach((element) => {
            if (item.link_url == element.title) {
              found = element;
            }
          });
          if (found.path) {
            location.href = found.path;
          }else{
            this.$toast('暂无链接')
          }
        });
    },
  },
  mounted() {
    this.getLifeList();
  },
};
</script>

<style scoped>
.home-title {
  color: #000;
  font-size: 17px;
  margin-bottom: 15px;
  font-weight: 700;
  padding-left: 20px;
}
.live-tab {
  background-color: #fff;
  border-radius: 15px 15px 0 0;
  padding: 25px 20px;
  display: flex;
  flex-wrap: wrap;
}
.live-tab-item {
  margin-bottom: 20px;
  text-align: center;
  width: 33%;
}
.live-icon {
  width: 45px;
  height: 45px;
}
.live-tab-name {
  font-size: 15px;
  color: #333;
  margin-top: 5px;
}
</style>
